'use client';
import React from "react";
import Image from "next/image";
import Link from "next/link";
import { Policy } from "@/types/policy";
import { Product } from "@/types/page-content";

interface FooterProps {
  products: Product[];
  policies: Policy[];
}

export default function FooterComponent({ products, policies }: FooterProps) {
  return (
    <footer className="footer">
      <div className="footer-main">
        <div className="container px-5 px-sm-0">
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <div className="fm-col col-6 col-sm-4">
              <h5 className="footer-col-title">Company</h5>
              <ul className="footer-menu">
                <li>
                  <Link href="/our-story" className="footer-list">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link href="/howto" className="footer-list">
                    How To
                  </Link>
                </li>
                <li>
                  <Link href="/learn" className="footer-list">
                    Latest Post
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://alethahealth.customerdesk.io/#rp-customer-widget-home"
                    className="footer-list"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="fm-col col-6 col-sm-4">
              <h5 className="footer-col-title">Products</h5>
              <ul className="footer-menu">
                {products
                  ?.filter((product: Product) => product.slug !== "tfl-ball")
                  ?.map((product: Product, index: number) => (
                    <li key={index}>
                      <Link href={`/${product.slug}`} className="footer-list">
                        {product?.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="fm-col col-12 col-sm-4 mt-5 mt-sm-0">
              <h5 className="footer-col-title">Help & Information</h5>
              <ul className="footer-menu">
                <li>
                  <Link
                    href="https://alethahealth.customerdesk.io/#rp-customer-widget-home"
                    className="footer-list"
                  >
                    Support
                  </Link>
                </li>
                <li>
                  <Link href="/manuals-safety" className="footer-list">
                    Manuals & Safety Information
                  </Link>
                </li>
                {policies?.map(
                  (policy: Policy, index: number) =>
                    policy.slug !== "safety-terms-of-use" && ( // Add this condition
                      <li key={index}>
                        <Link
                          href={`/policies/${policy.slug}`}
                          className="footer-list"
                        >
                          {policy.title}
                        </Link>
                      </li>
                    )
                )}

                <li>
                  <Link href="/patents" className="footer-list">
                    Patents
                  </Link>
                </li>
                <li>
                  <Link href="/affiliates" className="footer-list">
                    Affiliates
                  </Link>
                </li>
                <li>
                  <ul className="social-list d-flex justify-content-center justify-content-sm-start">
                    <li>
                      <Link href="https://x.com/alethahealth/" target="_blank">
                        <Image
                          src="/assets/images/v2/twitter-icn.svg"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://www.instagram.com/alethahealth/"
                        target="_blank"
                      >
                        <Image
                          src="/assets/images/v2/instagram-icn.svg"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://www.facebook.com/alethahealth"
                        target="_blank"
                      >
                        <Image
                          src="/assets/images/v2/facebook-icn.svg"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://www.youtube.com/c/AlethaHealth"
                        target="_blank"
                      >
                        <Image
                          src="/assets/images/v2/youtube-icn.svg"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-btm text-center">
        <div className="container">
          <p>2024 &copy; Aletha Inc.</p>
        </div>
      </div>
    </footer>
  );
}
